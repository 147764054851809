import React from 'react';
import Modal from 'react-modal';

const FlipHTML5Modal = ({ isOpen, onRequestClose, href, width, height, content }) => {

  return (
    <>
      <Modal 
        isOpen={isOpen} 
        onRequestClose={onRequestClose} 
        shouldCloseOnEsc={true}       
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <div className="modal-box" dangerouslySetInnerHTML={{ __html: content }} />
      </Modal>
    </>
  );
};

export default FlipHTML5Modal;
