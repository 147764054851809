import React, { useState, useEffect } from 'react';
import lotus from '../assets/images/lotus.png';
import Book from '../assets/images/ShamsLotus.pdf';
import { FaDownload, FaFolderOpen, FaBook } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive'
import MyModal from './MyModal'

const handleDownload = () => {
  window.open(Book, '_blank');
};

const Nav = () => {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [htmlModalIsOpen, setHtmlModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const openHTMLModal = () => {
    setHtmlModalIsOpen(true)
  }

  const openHtmlModal = () => {
    setHtmlModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setHtmlModalIsOpen(false);

  };
  const handleClick = (event) => {
    event.preventDefault();
    const rel = event.target.getAttribute('data-rel');
    const href = event.target.getAttribute('data-href');
    const width = event.target.getAttribute('data-width');
    const height = event.target.getAttribute('data-height');
    const title = event.target.getAttribute('data-title');
    const iframeHtml = `
      <iframe src="${href}" width="${width}" height="${height}" title="${title}"></iframe>
    `;
    window.open('', '_blank').document.write(iframeHtml);
  };

  const isBigScreen = useMediaQuery({ query: '(min-width: 1300px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const content = '<div class="highlight"><a href="/book" className="bgnone is-warning"> اقرأ/ي الكتاب </a></div><div class="bottom"><a className="btn" href="https://shamslotus.info/static/media/ShamsLotus.bdde1dd8bf55f7bb258b.pdf" className="bgnone is-warning" target="_blank">تحميل الكتاب</a><a target="_blank" href="https://online.fliphtml5.com/jfbwm/wyey/" className="bgnone is-warning">تصفح الكتاب</a><a href="order"/>اطلب نسخة</a></div>'
  
  const handleOpenHtmlModel = () => {
    openHTMLModal(); 
  };
  
  return (
    <>
    <nav className="navbar" role="navigation">
      {isTabletOrMobile || isPortrait ? (
        <>
          <a href='/'><h3 className='mobile-title'>المعنى الإنساني لزهرة اللوتس</h3></a>
          <div className="navbar right mobilespecial">
          <a href="#" className="navbar-item" onClick={handleOpenHtmlModel}>
              <button className="bgnone is-warning">
                <FaBook />  الكتاب
              </button>
            </a>
          </div>
          <div id="navbarBasicExample" className="navbar-menu">
            <a href="/shamslotus" className="navbar-item">مدخل</a>
            <a href="/darshams" className="navbar-item">دارشمس</a>
            <a href="/cover" className="navbar-item"> الغلاف</a>
            <a href="/intro" className="navbar-item">لمحات</a>
          </div>
        </>
      ) : isBigScreen ? (
        // Content for big screens
        <>
          <a href="/">
            <img src={lotus} alt="Lotus" className="hidemobile" />
          </a>
          <div className="navbar-menu">
            <a href="/shamslotus" className="navbar-item">مدخل</a>
            <a href="/darshams" className="navbar-item">دارشمس</a>
            <a href="/cover" className="navbar-item"> الغلاف</a>
            <a href="/intro" className="navbar-item">لمحات</a>
            <a href="#" className="navbar-item" onClick={handleOpenHtmlModel}>
              <button className="bgnone is-warning">
                <FaBook />  الكتاب
              </button>
            </a>
          </div>
          <hr />
        </>
      ) : (
        <>
          <a href='/'><h3 className='mobile-title'>المعنى الإنساني لزهرة اللوتس</h3></a>
          <div className="navbar right mobilespecial">
          <a href="#" className="navbar-item" onClick={handleOpenHtmlModel}>
              <button className="bgnone is-warning">
                <FaBook />  الكتاب
              </button>
            </a>  
          </div>
          <div id="navbarBasicExample" className="navbar-menu">
            <a href="/shamslotus" className="navbar-item">مدخل</a>
            <a href="/darshams" className="navbar-item">دارشمس</a>
            <a href="/cover" className="navbar-item"> الغلاف</a>
            <a href="/intro" className="navbar-item">لمحات</a>
          </div>
        </>
      )}
    </nav>

    <MyModal
        isOpen={htmlModalIsOpen}
        onRequestClose={closeModal}
        width="400"
        shouldCloseOnEsc={true}
        height="400"
        content={content}
      />
  </>
  );
};

export default Nav;
