import React from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const About = () => {
  return (
<div className='columns'>
  <div className='column bg full-height is-one-third sticky home-sticky'>
  
  </div>
  <div className='column full-height top-title pad is-three-quarters'>
    <div className='content home'>
        <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />
          </div>
          <div className='article'>
          <br />
          <p className='ff'>
          للوهلة الأولى تبدو ظاهرة تقديس عدد من الحضارات لجمال زهرة اللوتس مثار تساؤل. فلماذا بزغت زهرة اللوتس عبر طقوس تكاد أن تكون متطابقة في الحياة الروحية لحضارات متباعدة، والتي لم يكن بينها أي اتصال؟
          </p>
          <p>
          ولماذا أسبغ الراحلون منذ آلآف السنين على هذه الزهرة مغزى انسانّي المعنى؟ لا شك في أن هناك طبعًا الكثير من الزهور الجميلة في الطبيعة، ولكن وثائق التاريخ تولي اللوتس تميًزا وكأنها تعبير عن تفرد خاص، كالشمس في .السماء ليس لها من مقارنة.
          </p>
          <p>
          وليس ذكر الشمس ببعيد عن اللوتس، فهي تفتح أوراق زهرتها في الصباح وتغلقها حين يأتي أوآن الغروب. وقد أدرك الراحلون عمقًا وجوديًا لزهرة اللوتس، وكأنها إشارة عن كنه خفي لدورة الحياة والموت والولادة من جديد، كما تشير إليه العقائد المسجلة بدأب .وبنصوص شعرية تفوح رونقًا وحبورا.
          </p>
          <p>
          وما زالت زهرة اللوتس حاليًا مثار تقدير في عدد من المجالات الإبداعية في الفنون والعمران المعاصر، وأيضا في مجال الصناعة التي تستخدم خاصية مناعة أوراقها ضد التلوث بالمحيط في إنتاج سطوح تتصف بنقاء استثنائي.
          </p>
          <p>
          ولربما اعتبرت خاصية النقاء الذي تبديه اللوتس - والتي تنمو في المستنقعات - وكأنها تتحدى تأثير المحيط الموحل حولها - إذ أنها بما حولها لا تتأثر. أليس هذا المغزى من قدرة اللوتس على الحفاظ على الصفاء دون اكتراث بشوائب المحيط ما يتمناه كل فرد يعيش في عالم من المساوئ والتحديات؟
          </p>
          <p>
          ذلك ما تشير إليه أيضا كتابات الفلسفة الشرقيّة وبشكل خاص سجل "فلسفة اللوتس" - والذي هو مجال .اعتبار عالمي في مجال الفكر، وموضع تقدير كبير.
          </p>
          <p>
          و يشير المعنى المفيد لتطبيقات "فلسفة اللوتس" في الواقع اليومي إلى مفاهيم بالغة الأهمية حول إمكانية الفرد الوصول إلى وضع الحرية من القيود النفسية وإلى الوصول إلى حالة الإنارة الفكرية. فأكثر ما في الوجود من فائدة يستنير بها الفكر هو معرفة كيف تؤدي الأسباب إلى تبعات ونتائج. فما هو كنه ذلك الرابط الخفي بين العمل الذي نقوم به حالياً ونتيجته المستقبليّة من سعادة أو معاناة؟
          </p>
          <p>
          تشير زهرة اللوتس في مغزى تكوينها إلى قانون "ارتباط السبب بالنتيجة": حيث أنها تفرز البذور – والتي هي السبب في تواجدها – في نفس الوقت الذي تبلغ فيه النتيجة: والكائنة وجودها كزهرة متأججة كاملة. ويتطرق هذا الكتاب إلى ذكر المبادئ الفكرية والنفسية حول .إيحاءات فلسفة اللوتس في الواقع اليومي للفرد في اتجاهه إلى النمو باتجاه وضع الإنارة.
          </p>
          <p>
          يطرح الكتاب أيضا سؤالا فلسفيًا حول موضوع الجمال بشكل عام، ذلك أن اكتشاف مستحاثات لزهرة اللوتس تعود إلى عشرات الملايين من السنين قبل ظهور الإنسان – يثير في الفكر التساؤل: هل يمكن اعتبار جمال زهور ما قبل التاريخ خاصيّة كونية لاعلاقة لها بانطباع الإنسان؟ وهل هذا الإعتبار يتناقض مع التعريف المتداول للجمال، كونه انطباعاً انساني المصدر؟
          </p>
          <p>
          يسبر هذا الكتاب في عرضه للمواضيع المقدّمة فيه عدداً من المواضيع والحقائق المستندة إلى مراجع لاريب في صحتها. وكذلك، تشمل محتويات الكتاب الإشارة إلى حقو ٍل تتجاوز تقييدات المكان والزمان، بما يفتح آفاقاً وعوالماً من ثراء وقيمة ذات مغزى مفيد في الواقع النفسي للإنسان. 
          </p>
          <br /><br />
          <Footer />
          </div>
         
        </div>
  </div>

</div>
  )
}

export default About
