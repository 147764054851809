import React from 'react'
import Nav from '../components/Nav'

const Thanks = () => {
  return (
<div className='columns'>
  <div className='column bg full-height is-one-third sticky home-sticky'>
    <div id="book">
    </div>
  </div>
  <div className='column full-height top-title pad is-three-quarters'>
  <div className='content home'>
        <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />
          </div>
          <div className='article'>
            <br />
            <h3 className='small'>تم أرسال طلب نسخة بنجاح</h3>
            <br />
            <p className='none'>سيتم التواصل معكم قريباً</p><br />
            <a href='mailto:safwan@shamslotus.info'>صفوان دارشمس</a>
          </div>
          <br /><br />
        </div>
      </div>
      
    </div>
  )
}

export default Thanks
