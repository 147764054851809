import React, { useState } from 'react';
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Front from '../assets/images/paper3-min.jpeg'
import { useMediaQuery } from 'react-responsive'

const Home = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  if (isTabletOrMobile) {
    return (
      <div className='columns'>
        <div className='column bg full-height is-one-third sticky home-sticky'>
          <div id="book">
          </div>
        </div>
        <div className='column full-height top-title pad is-three-quarters'>
          <div className='content home'>
            <div className='main-nav'>
                <div id='flower'></div>
                <Nav />
                <br />
                <hr />
              </div>
              <div className='article'>
                <div>
                  <img src={Front} className='res' alt=""/><br /><br />
                </div>
              <p className='ff'>
                رحلة ثقافية ممتعة عبر عدد من الحضارات التي أدركت أن هناك مغزى ذو أبعاد روحية وإنسانية لزهرة اللوتس. ولا تزال خواص هذة الزهرة محل تقدير في عدد من المجالات الفكرية والفلسفية، ومحل اهتمام في تطبيقات إبداعية في حقول الفنون والعمران وأيضاً في مجال صناعة المواد المضادة للتلوث.
              </p>
              <p>
              يقدم الكتاب شرحاً لظاهرة تقديس اللوتس في الطقوس الروحية لعدد من الحضارات المتباعدة والتي لم يكن بينها أي اتصال، بما يوحي أن الإدراك والأبداع ليس مركزاً في منطقة أو حضارة ما. فظاهرة "بزوغ التفكير المتشابه" لدى عديد من الحضارات تبين حقيقة مثيرة للشغف: وهي إمكانية ظهور نفس الأفكار في عدد من الأماكن دون أن يكون هناك بينها اتصال مباشر. وهذة الظاهرة التي يؤيدها الواقع تمتدّ لتشمل أيضاً الأفكار الروحية، والذي ساهمت الحضارات القديمة في إعطاء الدلائل على صحتها في .مجال تقديسها للوتس، وارتباط هذة الزهرة بالحياة الروحية للإنسان.
              </p>
              <p>
              ويتّطرق الكتاب إلى الإشارة إلى التأثير الروحي الكبير الذي توحيه اللوتس على حقل الفكر الفلسفي، وبشكل خاص يشار إلى "فلسفة اللوتس" التي تتخذ من هذة الزهرة عنواناً ومثالاً ذو مغزى وجودي عميق. ذلك أن قدرة اللوتس على النمو بصدوح ورونق في مستنقعات موحلة دون اكتراث أو تأثّر بشوائب المحيط - يمكن أن يؤخذ مثالا للفرد لمحاكاته لينمو بجدارة رغم ضغوطات الحياة محافظاً على صلابة ونقاء كيانه في الوجود.
              </p>
              <p>
              يطرح الكتاب أيضاً سؤالا فلسفيا قيد النقاش، اذ يصعب ايجاد اجابه باتة له: هل الجمال والرونق الذي تجسده اللوتس ميزة من صفات الطبيعة لا علاقة لها بالانطباع الشعوري للإنسان؟ ذلك أن توفر وجود مستحاثات لزهرة اللوتس تعود إلى ملايين السنين قبل ظهور الانسان يمكن ان يشير الى ان الجمال عموماً .والذي تمثله اللوتس بشكل خاص، إنما ظاهرة كونية الكنه مستقلة عن الانطباعات الشخصية للإنسان.
              </p>
              <Footer />
              </div>
            </div>
        </div>
        
      </div>
    )
  }
  return(
    <div className='columns'>
      <div className='column bg full-height is-one-third sticky home-sticky'>
        <div id="book">

        </div>
      </div>
      <div className='column full-height top-title pad is-three-quarters '>
        <div className='content home'>
          <div className='main-nav'>
              <div id='flower'></div>
              <Nav />
              <hr />
            </div>
            <div className='article'>
            <br />
            <p className='up'>
              رحلة ثقافية ممتعة عبر عدد من الحضارات التي أدركت أن هناك مغزى ذو أبعاد روحية وإنسانية لزهرة اللوتس. ولا تزال خواص هذة الزهرة محل تقدير في عدد من المجالات الفكرية والفلسفية، ومحل اهتمام في تطبيقات إبداعية في حقول الفنون والعمران وأيضاً في مجال صناعة المواد المضادة للتلوث.
            </p>
            <p>
            يقدم الكتاب شرحاً لظاهرة تقديس اللوتس في الطقوس الروحية لعدد من الحضارات المتباعدة والتي لم يكن بينها أي اتصال، بما يوحي أن الإدراك والأبداع ليس مركزاً في منطقة أو حضارة ما. فظاهرة "بزوغ التفكير المتشابه" لدى عديد من الحضارات تبين حقيقة مثيرة للشغف: وهي إمكانية ظهور نفس الأفكار في عدد من الأماكن دون أن يكون هناك بينها اتصال مباشر. وهذة الظاهرة التي يؤيدها الواقع تمتدّ لتشمل أيضاً الأفكار الروحية، والذي ساهمت الحضارات القديمة في إعطاء الدلائل على صحتها في .مجال تقديسها للوتس، وارتباط هذة الزهرة بالحياة الروحية للإنسان.
            </p>
            <p>
            ويتّطرق الكتاب إلى الإشارة إلى التأثير الروحي الكبير الذي توحيه اللوتس على حقل الفكر الفلسفي، وبشكل خاص يشار إلى "فلسفة اللوتس" التي تتخذ من هذة الزهرة عنواناً ومثالاً ذو مغزى وجودي عميق. ذلك أن قدرة اللوتس على النمو بصدوح ورونق في مستنقعات موحلة دون اكتراث أو تأثّر بشوائب المحيط - يمكن أن يؤخذ مثالا للفرد لمحاكاته لينمو بجدارة رغم ضغوطات الحياة محافظاً على صلابة ونقاء كيانه في الوجود.
            </p>
            <p>
            يطرح الكتاب أيضاً سؤالا فلسفيا قيد النقاش، اذ يصعب ايجاد اجابه باتة له: هل الجمال والرونق الذي تجسده اللوتس ميزة من صفات الطبيعة لا علاقة لها بالانطباع الشعوري للإنسان؟ ذلك أن توفر وجود مستحاثات لزهرة اللوتس تعود إلى ملايين السنين قبل ظهور الانسان يمكن ان يشير الى ان الجمال عموماً .والذي تمثله اللوتس بشكل خاص، إنما ظاهرة كونية الكنه مستقلة عن الانطباعات الشخصية للإنسان.
            </p>
            </div>
            <Footer />
          </div>
      </div>
    </div>
  ) 
}

export default Home
