import React from 'react'
import safwan from '../assets/images/safwan.jpg';
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const Author = () => {
  return (
<div className='columns'>
<div className='column bg full-height is-one-third sticky home-sticky'>
  </div>

  <div className='column full-height top-title pad is-three-quarters'>
  <div className='content home'>
        <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />        
            </div>
          <div className='columns article author'>
            <div className='column is-one-third sixteen'>
              <img alt='المعنى الإنساني لزهرة اللوتس' src={safwan} className='half res' />
            </div>
            <div className='column is-three-quarters'>
              <h4 className='unboldno-margin'>الإسم الأدبي لصفوان الزعبلاوي</h4>
              <p className='mail'>من مواليد دمشق 1947</p>
            </div>
          </div>
          <div className='article'>
          <p>
          استوحي هذا الإسم من أصل كلمة "دمشق" والذي كانت تدعى "دارمسق" أي الدار المسقية لكثرة مياهها مما أوحى باسم "دارشمس" توسما بالانارة.
          </p>
          <p>
          في عمر التاسعة عشر، سافر دارشمس للدراسة في بولونيا حيث حصل على دبلوم في الإلكترونيات في عام 1973، ومن ثم عمل في مؤسسة المواصلات في دمشق قبل هجرته إلى استراليا في 1983 حيث عمل في مجال الاتصالات ثم في دائرة المعلومات في المكتبة الوطنية في سيدني، قبل تقاعده في 2016.
          </p>
          <p>
          وخلال تلك الفترة التحق "دارشمس" بعدد من الدورات الدراسية في حقل الفلسفة، وقام بكتابة ونشر خمسة عشرة دراسة فلسفية موضوعها المقارنة بين الفلسفة الغربية والفلسفة الشرقية - وقد نشرت المقالات في مجلة فلسفية تابعة لجامعة أوكسفورد.					
          </p>
          <p>
          وجد "دارشمس" موضوع انتشار الأفكار العلمية والروحية في تاريخ الحضارات بشكل مستقل عن بعضها البعض - أمًرا يستحق الاهتمام، إذ أنه يحرر الفكر من الظن أن الإلهام العلمي أو الفني والروحي احتكار لجهة دون غيرها.
          </p>
          <p>
          وبشكل خاص، وجد "دارشمس" أن زهرة اللوتس تحمل مكانة روحية خاصة في عدد من الحضارات التي لم يكن بينها اتصال، موضوعاً يثير الشغف.          
          </p>
          <p>
          يقيم "دارشمس" في مدينة سيدني، ويعمل يوميًا في الكتابة على مواقع الشبكة الرقمية في مجال العلاقات الاجتماعية والمواضيع الفلسفية.
          </p>
          <p>
            <a href='https://www.darshams.info' target='_blank'>www.darshams.info</a><br />
            <a href='mailto:safwan@shamslotus.info' target='_blank'>safwan@shamslotus.info</a>
          </p>
          <br /><br />
          <Footer />
          </div>
         

        </div>
      </div>
      
    </div>
  )
}

export default Author
