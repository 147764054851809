import React, { useState } from 'react'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import { useForm, ValidationError } from '@formspree/react';

const Order = () => {
    const [state, handleSubmit] = useForm("xleybzjj");
    if (state.succeeded) {
        window.location.href = '/thanks'
        return null;
    }

  return (
<div className='columns'>
<div className='column bg full-height is-one-third sticky home-sticky'>
  </div>

  <div className='column full-height top-title pad is-three-quarters'>
  <div className='content home'>
        <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />        
        </div>
        <div className='article'>
        <br />
        <p class='none'>لطلب نسخة من الكتاب يرجى تعبئة النموذج وسيتم التواصل معكم بخصوص التكلف واجور الشحن اينما كنتم</p>
        <form className="shipping-form" onSubmit={handleSubmit}>
            <div className="form-group form-cols">
                <div className='form-column'>
                    <label htmlFor="fullName">
                        الاسم الكامل
                    </label>
                    <input
                        id="fullName"
                        type="text" 
                        name="fullName"
                    />
                    <ValidationError 
                        prefix="الاسم الكامل" 
                        field="fullName"
                        errors={state.errors}
                    />
                    <label htmlFor="street">
                        الشارع
                    </label>
                    <input
                        id="street"
                        type="text" 
                        name="street"
                    />
                    <ValidationError 
                        prefix="الشارع" 
                        field="street"
                        errors={state.errors}
                    />
                    <label htmlFor="state">
                        الولاية
                    </label>
                    <input
                        id="state"
                        type="text" 
                        name="state"
                    />
                    <label htmlFor="zipcode">
                        الرمز البريدي
                    </label>
                    <input
                        id="zipcode"
                        type="text" 
                        name="zipcode"
                    />
                    <ValidationError 
                        prefix="الرمز البريدي" 
                        field="zipcode"
                        errors={state.errors}
                    />
                </div>

                <div className='form-column'>
                <label htmlFor="email">
                        البريد الإلكتروني
                    </label>
                    <input
                        id="email"
                        type="email" 
                        name="email"
                    />
                    <ValidationError 
                        prefix="البريد الإلكتروني" 
                        field="email"
                        errors={state.errors}
                    />
                    
                    <label htmlFor="city">
                        المدينة
                    </label>
                    <input
                        id="city"
                        type="text" 
                        name="city"
                    />
                    <ValidationError 
                        prefix="المدينة" 
                        field="city"
                        errors={state.errors}
                    />
                    
                    <ValidationError 
                        prefix="الولاية" 
                        field="state"
                        errors={state.errors}
                    />
                    <label htmlFor="country">
                        البلد
                    </label>
                    <input
                        id="country"
                        type="text" 
                        name="country"
                    />
                    <ValidationError 
                        prefix="البلد" 
                        field="country"
                        errors={state.errors}
                    />
                    <label htmlFor="phoneNumber">
                        رقم الهاتف
                    </label>
                    <input
                        id="phoneNumber"
                        type="text" 
                        name="phoneNumber"
                    />
                    <ValidationError 
                        prefix="رقم الهاتف" 
                        field="phoneNumber"
                        errors={state.errors}
                    />
                    
                </div>
            </div>

            <button type="submit" disabled={state.submitting}>
                اطلب نسختك
            </button>
        </form>

        <br /><br />
        <Footer />
        </div>
        

        </div>
      </div>
      
    </div>
  )
}

export default Order
