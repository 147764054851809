import React from 'react'

const Footer = () => {
  return (
    <div id='footer'>
        <hr />
      <p> دارشمس 2023</p>
    </div>
  )
}

export default Footer
