import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import Index from '../components/Index'
import 'swiper/css';
import 'swiper/css/navigation';
import One from '../assets/images/1.jpg';
import Two from '../assets/images/2.jpg';
import Three from '../assets/images/3.jpg';
import Four from '../assets/images/4.jpg';
import Five from '../assets/images/5.jpg';
import Six from '../assets/images/6.jpg';
import Seven from '../assets/images/7.jpg';
import Eight from '../assets/images/8.jpg';
import Nine from '../assets/images/9.jpg';
import Ten from '../assets/images/10.jpg';
import Eleven from '../assets/images/11.jpg';
import Twelve from '../assets/images/12.jpg';
import Thirteen from '../assets/images/13.jpg'

const Intro = () => {
 
  const indexData = [
    {
      text: 'الفصل الأول: مغزى زهرة اللوتس',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'مبدأ عدم التأثر بظروف المحيط العكر', photo: 'two' },
        { text: 'الفارق بين "السبب الضمني" والظروف الخارجية"', photo: '2' },
        { text: 'هل يمكن تغيير المصير', photo: '3' },
        { text: 'دورة الحياة والموت والولادة من جديد', photo: '4' },
        { text: 'خاصيّة التنظيف الذاتي', photo: '6' },
        { text: 'لوتس الأمازون', photo: '7' },
        { text: 'اللوتس سابقة لظهور الإنسان', photo: '8' },
        { text: 'اللوتس في العصر الحديث', photo: '9' }
      ],
    },
    {
      text: 'الفصل الثاني : ظهور اللوتس في الحضارات الإنسانية',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'الصفات الروحية لزهرة اللوتس', photo: '1' },
        { text: 'معنى التكوين والإبداع', photo: '1' },
        { text: 'هل للكون اتجاه ورغبات؟', photo: '1' },
        { text: 'النشوة في إثارة الحواس الخمسة', photo: '2' },
        { text: 'اللوتس الأمريكية', photo: '3' },
        { text: 'اللوتس المصرية', photo: '4' },
        { text: 'اللوتس الآسيوية', photo: '6' },
        { text: 'اللوتس في حضارات الشرق الأوسط', photo: '7' }
      ],
    },
    {
      text: 'الفصل الثالث : أحجية المفاهيم المتشابهة',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'التشابه بين مفاهيم حضارات متباعدة', photo: '1' },
        { text: 'تشابه مفهومي ال"كا"وال"كارما"', photo: '2' },
        { text: 'قدسية الحياة في كل الكائنات', photo: '3' }
      ],
    },
    {
      text: 'الفصل الرابع : زهرة اللوتس في العقائد الدينية القديمة',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'زهرة اللوتس في معتقدات حضارة أمريكا الوسطى', photo: '2' },
        { text: 'الأدعية الدينية المسجلة في "كتاب الراحلين" في مصر القديمة', photo: '3' },
        { text: 'زهرة اللوتس في التعاليم الدينية في شرق آسيا', photo: '3' }
      ],
    },
    {
      text: 'الفصل الخامس: تعاليم سوترا اللوتس',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'الإحساس بروعة سحر الوجود', photo: '2' },
        { text: 'الجو الغزير بخيال من السحر والمتعة', photo: '3' },
        { text: 'هل لوجود الحياة في الكون هدف أو إرادة؟', photo: '4' },
        { text: 'الحريّة من الماضي', photo: '4' },
        { text: 'مفهوم أبدية الزمن', photo: '4' },
        { text: 'الطبيعة الفطرية للإنارة', photo: '5' }
      ],
    },
    {
      text: 'الفصل السادس: موجز حياة نيتشيرين (شمس لوتس)',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'مرحلة الدراسة', photo: '2' },
        { text: 'إعلان التعاليم الجديدة', photo: '3' },
        { text: 'مجابهة السبطة الحاكمة', photo: '4' },
        { text: 'محاولة إعدام نيتشيرين', photo: '4' },
        { text: 'تحويل حياة المنفى إلى إنجازات', photo: '4' },
        { text: 'التحضر للإستقرار قبل الرحيل', photo: '5' }
      ],
    },
    {
      text: 'الفصل السابع: حول كتابات نيتشيرين',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'حول بلوغ الإنارة في الحياة الحالية', photo: '2' },
        { text: 'ماهو سبب المعاناة؟', photo: '3' },
        { text: 'تكامل المادّة والفكر دون فصل بينهما', photo: '4' },
        { text: 'التفكير الدوني واستصغار الذات', photo: '4' },
        { text: 'زهرة اللوتس رمز اتزان السبب ونتيجته', photo: '4' },
        { text: 'ندرة تعاليم اللوتس', photo: '5' }
      ],
    },
    {
      text: ' الفصل الثامن : مقتطفات من كتابات نيتشيرين',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'إني لا أعرف شتاء لم يتبعه الربيع', photo: '2' },
        { text: 'إنارة جميع النساء', photo: '3' },
        { text: 'أندر مافي الوجود', photo: '4' },
        { text: 'السعادة في هذا العالم', photo: '4' },
        { text: 'إنسان الحكمة يجابه الإضطهاد دون خوف من أحد', photo: '4' }
      ],
    },
    {
      text: 'الفصل التاسع : انطباعات اللوتس في المجتمع الحديث',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'اللوتس كرمز للقيمة والتقدير', photo: '2' },
        { text: 'اللوتس كشعار وطني', photo: '3' },
        { text: 'التأثيرات الثقافية والفنيّة الحديثة', photo: '4' },
        { text: 'التنظيف الذاتي للوتس في استخدامات صناعية', photo: '4' },
        { text: 'الجمال العمراني المستوحى من اللوتس', photo: '5' } 
      ],
    },
    
    {
      text: 'الفصل العاشر : اللامركزية في الإدراك',
      url: '#chapter1',
      photo: 'section1',
      subheadings: [
        { text: 'هل الجمال خاصيّة كونية؟', photo: '2' },
        { text: 'الجمال، والدافع الجنسي', photo: '3' },
        { text: 'مدلول صفات زهرة اللوتس على حياة الإنسان', photo: '4' },
        { text: 'استخدام اللوتس في مواد التجميل', photo: '4' },
        { text: 'فلسفة اللوتس وظاهرة اللامركزية في الإدراك', photo: '5' } 
      ],
    }
  
  ];

  SwiperCore.use([Autoplay, Navigation]);

  return (
    <div className='columns'>
      <div className='column bg full-height is-one-third sticky slider-sticky plain'>
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          loop={true}
          grabCursor={true}
          autoplay={{ delay: 3000 }}
          navigation
        >
          <SwiperSlide><img src={One}  alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Two}   alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Three}  alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Twelve}  alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Four}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Five}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Six}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Seven}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Eight}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Nine}   alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img src={Ten}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img  src={Eleven}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
          <SwiperSlide><img  src={Thirteen}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
        </Swiper>
      </div>
      <div className='column full-height top-title pad is-three-quarters'>
      <div className='content home'>
          <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />
          </div>
          <div className='article'>
      <br />
            <div className='mobile-only'>
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              loop={true}
              grabCursor={true}
              autoplay={{ delay: 3000 }}
              navigation
            >
              <SwiperSlide><img src={One}  alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Two}   alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Three}  alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Twelve}  alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Four}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Five}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Six}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Seven}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Eight}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Nine}   alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img src={Ten}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img  src={Eleven}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
              <SwiperSlide><img  src={Thirteen}    alt="المعنى الإنساني لزهرة اللوتس" /></SwiperSlide>
            </Swiper>
            </div>
            <Index indexData={indexData} />
            <br /><br />
          <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;