import { BrowserRouter, Route, Routes} from "react-router-dom";
import Home from './pages/Home'
import Author from './pages/Author'
import Cover from './pages/Cover'
import About from './pages/About'
import Intro from './pages/Intro';
import Order from './pages/Order';
import NotFound from './pages/NotFound';
import Thanks from './pages/Thanks';

function App() {
  return(
    <BrowserRouter>
      <div className='App'>
          <Routes>
              <Route exact path='/' element={ <Home /> } />
              <Route exact path='/darshams' element = { <Author /> } />
              <Route exact path='/shamslotus' element = { <About /> } />
              <Route exact path='/cover' element = { <Cover /> } />
              <Route exact path='/intro' element = { <Intro /> } />
              <Route exact path='/order' element = { <Order /> } />
              <Route exact path='/thanks' element = { <Thanks /> } />
              <Route path='*' element = { <NotFound /> } />
          </Routes>
      </div>
    </BrowserRouter>
  )
}
 
export default App