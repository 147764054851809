import React from 'react'
import Nav from '../components/Nav'

const NotFound = () => {
  return (
<div className='columns'>
  <div className='column bg full-height is-one-third sticky home-sticky'>
    <div id="book">
    </div>
  </div>
  <div className='column full-height top-title pad is-three-quarters'>
  <div className='content home'>
        <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />
          </div>
          <div className='article'>
            <h3>الصفحة غير موجودة</h3>
          </div>
          <br /><br />
        </div>
      </div>
      
    </div>
  )
}

export default NotFound
