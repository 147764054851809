import React from 'react'
import art from '../assets/images/cover2.png';
import Nav from '../components/Nav'
import Footer from '../components/Footer'

const Author = () => {
  return (
<div className='columns'>
  <div className='column bg full-height is-one-third sticky home-sticky'>
    <div id="book">
    </div>
  </div>
  <div className='column full-height top-title pad is-three-quarters'>
  <div className='content home'>
        <div className='main-nav'>
            <div id='flower'></div>
            <Nav />
            <hr />
          </div>
          <div className='article'>
            <br />
          <p>
          شارك في تصميم الغلاف الفنانان السيدة إبداع زعبلاوي والسيد محمد وحيد باستخدام خلفية من ورق البردي لإظهار تصوير من نحت جداري في معبد حيبيس في واحة الخارجة، عاصمة محافظة الوادي الجديد – جنوب القاهرة.
          </p>
          <p>
          ويبين التصوير ظهور طفل من زهرة اللوتس تعبيرا عن صلة الزهرة بالإنسان محاطاً بآلهة الحماية والتقديس، ويرمز هذا النحت التصويري إلى فكر روحي يجمع الأرض بالسماء حيث ترمز اللوتس إلى بزوغ من أرض الواقع وترمز .الشمس الى قدرات السماء.
          </p>
          <p>
          هناك تصاوير مختلفة من حضارات أخرى تبين ارتباط زهرة اللوتس بالوجود الإنساني، ولكن بساطة ووضوح هذا الرسم .الهيروغليفي يبدو اكثر ملائمة لعنوان هذا الكتاب: "المعنى الإنساني لزهرة اللوتس".
          </p>
          <div className='center'><img src={art} alt='seperator' className='art' /></div>
          <br /><br />
          <Footer />
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default Author
