// Inside Index.js
import React from 'react';
import { FaHtml5 } from 'react-icons/fa';

const Index = ({ indexData }) => {
  const renderSubheadings = (subheadings) => {
    return (
      <ul>
        {subheadings.map((subheading, index) => (
          <li key={index}>
             ∙ {subheading.text}
            {subheading.subheadings && renderSubheadings(subheading.subheadings)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div id="index-container">
      {indexData.map((item, index) => (
        <div key={index} className='i-container '>
           <h6 className='black'>
            {item.text}
          </h6>
          {item.subheadings && renderSubheadings(item.subheadings)}
        </div>
      ))}
    </div>
  );
};

export default Index;
